import React from "react"

function NumberOfPeople({ formData, setFormData }) {
  return (
    <div className="planner-form-section">
      <h3>Les voyageurs</h3>
      <label htmlFor="numberOfPeople">Nombre de personnes</label>
      <input
        type="number"
        className="cardBorder"
        id="numberOfPeople"
        name="numberOfPeople"
        value={formData.numberOfPeople}
        onChange={(e) =>
          setFormData({ ...formData, numberOfPeople: e.target.value })
        }
        placeholder="Nombre total de voyageurs"
        min="1"
      />
    </div>
  )
}

export default NumberOfPeople
