import React from "react"

function NavigationButtons({ step, setStep, maxSteps }) {
  return (
    <div className="buttons">
      {step > 0 && (
        <button
          type="button"
          className="cardBorder"
          onClick={() => setStep(step - 1)}
        >
          Précédent
        </button>
      )}
      {step < maxSteps - 1 && (
        <button
          type="button"
          className="cardBorder"
          onClick={() => setStep(step + 1)}
        >
          Suivant
        </button>
      )}
      {step === maxSteps - 1 && (
        <button type="submit" className="cardBorder">
          Envoyer
        </button>
      )}
    </div>
  )
}

export default NavigationButtons
