import React from "react"

function Transportation({ formData, setFormData }) {
  return (
    <div className="planner-form-section">
      <h3>Préférences de transport</h3>
      <label htmlFor="transportation">
        Choisissez votre moyen de transport
      </label>
      <select
        id="transportation"
        name="transportation"
        className="cardBorder"
        value={formData.transportation}
        onChange={(e) =>
          setFormData({ ...formData, transportation: e.target.value })
        }
      >
        <option value="flights">Avions</option>
        <option value="trains">Trains</option>
        <option value="buses">Bus</option>
        <option value="car-rentals">Location de voitures</option>
      </select>
    </div>
  )
}

export default Transportation
