import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostLink from "../components/postLink"
import FormPlanner from "../components/planner/form"

export const Head = () => {
  const description =
    "Les meilleurs quartiers pour se loger dans les principales villes d’Europe, avec une sélection d’hôtels, d’appartements, de chambres d’hôtes B&B et d’auberges de jeunesse."
  return <Seo title="Nos sélections d’hébergements" description={description} />
}

const IndexPage = ({
  data: {
    allMdx: { edges },
  },
}) => {
  const MapPosts = edges
    .filter((edge) => edge.node.frontmatter.template === "booking")
    .map((edge) => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout content="page">
      <div>
        <header>
          <h1>Préparez votre voyage en Europe</h1>
        </header>
        <section>
          <FormPlanner />
        </section>
        <section>
          <h2>Où voulez-vous loger ?</h2>
          <div id="cards">{MapPosts}</div>
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMdx(sort: { order: ASC, fields: frontmatter___city }) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            path
            city
            template
          }
        }
      }
    }
  }
`
