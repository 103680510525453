import React from "react"

function SpecialRequirements({ formData, setFormData }) {
  return (
    <div className="planner-form-section">
      <h3>Besoins particuliers</h3>
      <label htmlFor="requirements">Indiquez vos besoins particuliers</label>
      <input
        type="text"
        className="cardBorder"
        id="requirements"
        name="requirements"
        value={formData.requirements}
        onChange={(e) =>
          setFormData({ ...formData, requirements: e.target.value })
        }
        placeholder="Régimes alimentaires, accessibilité, etc."
      />
    </div>
  )
}

export default SpecialRequirements
