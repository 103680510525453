import React from "react"

function ContactInfo({ formData, setFormData }) {
  return (
    <div className="planner-form-section">
      <h3>Vos informations de contact</h3>
      <label htmlFor="email">Adresse email</label>
      <input
        type="email"
        className="cardBorder"
        id="email"
        name="email"
        value={formData.email}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        placeholder="Votre adresse email"
        required
      />
    </div>
  )
}

export default ContactInfo
