import React from "react"

function Destinations({ formData, setFormData }) {
  return (
    <div className="planner-form-section">
      <h3>Destinations</h3>
      <label htmlFor="destinations">Pays ou villes</label>
      <input
        type="text"
        id="destinations"
        name="destinations"
        className="cardBorder"
        value={formData.destinations}
        onChange={(e) =>
          setFormData({ ...formData, destinations: e.target.value })
        }
        placeholder="Entrez les destinations"
      />
    </div>
  )
}

export default Destinations
