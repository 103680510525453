import React from "react"

function TravelDates({ formData, setFormData }) {
  return (
    <div className="planner-form-section">
      <h3>Dates de voyage</h3>
      <label htmlFor="startDate">Date de début</label>
      <input
        type="date"
        className="cardBorder"
        id="startDate"
        name="startDate"
        value={formData.startDate}
        onChange={(e) =>
          setFormData({ ...formData, startDate: e.target.value })
        }
      />
      <label htmlFor="endDate">Date de fin</label>
      <input
        id="endDate"
        name="endDate"
        className="cardBorder"
        type="date"
        value={formData.endDate}
        onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
      />
    </div>
  )
}

export default TravelDates
